import axios from "axios";
import {Message} from "element-ui";
import router from "../router/index";
import store from "../store/index";
import {exportExcel} from "@/utils/util";

export const Axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, //要调用的接口前缀，这个会拼接在url的前面
    timeout: 1000 * 60, // 请求超时时间(ms)
    headers: {"Content-Type": "Application/json"},
});

Axios.interceptors.request.use(
    (config) => {
        let token = store.state.token;
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        switch (response.data.code) {
            case 401:
                router.replace({
                    name: "/login",
                });
                break;
            case 403:
                Message.error("登录过期，请重新登录!");
                sessionStorage.clear();
                router.replace({
                    name: "/login",
                });
                break;
            case 500:
                Message.error(response.data.msg);
                break;
        }

        let contentType = response.headers["content-type"];
        if (response.request.responseType === "blob") {
            response.data.text().then(text => {
                if (text) {
                    try{
                        let res = JSON.parse(text)
                        if(res.code!==0){
                            Message.error(res.msg);
                        }
                    }catch (err){
                        if (response.data && contentType.includes("application/vnd.ms-")) {
                            exportExcel(response);
                        }
                    }
                }
            })
        }else{
            return response.data;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

//停车场类型
const parkingLotType_Format = function (code) {
  switch (code) {
    case 0:
      return "地下停车场";
    case 1:
      return "地面停车场";
    default:
      return code;
  }
};

export default {
  parkingLotType_Format,
};

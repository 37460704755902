import { Axios as http } from "@/utils/fetch";

export default {
    namespaced: true,
    state: {
        //停车场列表
        parkingLotList: [],
        //商圈列表
        businessList: [],
    },
    mutations: {
        setParkingLotList(state, list) {
            state.parkingLotList = list
        },
        setBusinessList(state, list) {
            state.businessList = list
        }
    },
    actions: {
        async getParkingLotList({commit}) {
            try {
                let res = await http.post("/parking/listAll", {});
                if (res.code === 0) {
                    commit('setParkingLotList', res.data)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async getBusinessList({commit}) {
            try {
                let res = await http.get("/business/circle/list/all");
                if (res.code === 0) {
                    commit('setBusinessList', res.data)
                }
            } catch (err) {
                console.log(err)
            }
        }
    }
}

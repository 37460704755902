<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    let token = sessionStorage.getItem("token");
    if (token) {
      this.$store.commit("SET_TOKEN", token);
      this.$store.dispatch("refreshToken");
    }
  },
};
</script>

<style lang="scss">
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>

export default {
    namespaced: true,
    state: {
        token:null,
        ws:null,
        info:null
    },
    mutations: {
        SET_TOKEN(state,data){
            state.token = data
        },
        SET_WS(state,data){
            state.ws = data
        },
        SET_INFO(state,data){
            state.info = data
        }
    }
}

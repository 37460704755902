export default {
  namespaced: true,
  state: {
    //岗亭出入口列表选中的
    passagewayChecked: [],
    //最新的出入信息
    info: {},
    parkingLotId: "",
  },
  mutations: {
    SET_PASSAGEWAYCHECKED(state, list) {
      state.passagewayChecked = list;
    },
    SET_INFO(state, data) {
      state.info = data;
    },
    SET_PARKINGLOTID(state, parkingLotId) {
      state.parkingLotId = parkingLotId;
      state.passagewayChecked = [];
    },
  },
};

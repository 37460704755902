import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "indexFrame",
    component: () => import("../views/indexFrame.vue"),
    redirect: "index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index.vue"),
      },
      //数据大屏
      {
        path: "/bigScreen",
        name: "bigScreen",
        component: () => import("../views/bigScreen/bigScreen.vue"),
      },
      //岗亭大屏
      {
        path: "/gangting-bigScreen",
        name: "gangting-bigScreen",
        component: () =>
          import("../views/gangting-bigScreen/gangting-bigScreen.vue"),
      },
      //停车场景密度分析
      {
        path: "/report/parkingDensity",
        name: "report-parkingDensity",
        component: () =>
          import("../views/report/parkingDensity/parkingDensity.vue"),
      },
      //用户喜好逻辑分析
      {
        path: "/report/userPreference",
        name: "report-userPreference",
        component: () =>
          import("../views/report/userPreference/userPreference.vue"),
      },
      //商圈繁荣层次分析
      {
        path: "/report/businessCircle",
        name: "report-businessCircle",
        component: () =>
          import("../views/report/businessCircle/businessCircle.vue"),
      },
      //车辆引流绩效分析
      {
        path: "/report/drainagePerformance",
        name: "report-drainagePerformance",
        component: () =>
          import("../views/report/drainagePerformance/drainagePerformance.vue"),
      },
      //强化多维集成管理分析
      {
        path: "/report/integratedManagement",
        name: "report-integratedManagement",
        component: () =>
          import(
            "../views/report/integratedManagement/integratedManagement.vue"
          ),
      },
      //数控防疫追溯分析
      {
        path: "/report/CNCEpidemicPrevention",
        name: "report-CNCEpidemicPrevention",
        component: () =>
          import(
            "../views/report/CNCEpidemicPrevention/CNCEpidemicPrevention.vue"
          ),
      },
      //车载出行绩效分析
      {
        path: "/report/carTravel",
        name: "report-carTravel",
        component: () => import("../views/report/carTravel/carTravel.vue"),
      },
      //碎片信号回流分析
      {
        path: "/report/signalReturn",
        name: "report-signalReturn",
        component: () =>
          import("../views/report/signalReturn/signalReturn.vue"),
      },
      //出入口列表
      {
        path: "/passageway/list",
        name: "passagewayList",
        component: () =>
          import("../views/passageway/passagewayList/passagewayList.vue"),
      },
      //出入口详情
      {
        path: "/passageway/list/:id",
        name: "passagewayDetail",
        component: () =>
          import("../views/passageway/passagewayList/passagewayDetail.vue"),
      },
      //出入口记录
      {
        path: "/passageway/record",
        name: "passagewayRecord",
        component: () =>
          import("../views/passageway/passagewayRecord/passagewayRecord.vue"),
      },
      //停车记录列表
      {
        path: "/passageway/parkingRecord",
        name: "parkingRecord",
        component: () =>
          import("../views/passageway/parkingRecord/parkingRecord.vue"),
      },
      //联合停车场记录
      {
        path: "/passageway/parkingGroupRecord",
        name: "parkingGroupRecord",
        component: () =>
          import(
            "../views/passageway/parkingGroupRecord/parkingGroupRecord.vue"
          ),
      },
      //手动开闸列表
      {
        path: "/passageway/openingList",
        name: "openingList",
        component: () =>
          import("../views/passageway/openingList/openingList.vue"),
      },
      //充电记录列表
      {
        path: "/passageway/chargeRecord",
        name: "chargeRecord",
        component: () =>
          import("../views/passageway/chargeRecord/chargeRecord.vue"),
      },
      //车辆列表
      {
        path: "/vehicle/list",
        name: "vehicleList",
        component: () => import("../views/vehicle/vehicleList.vue"),
      },
      //车辆详情
      {
        path: "/vehicle/list/:id",
        name: "vehicleDetail",
        component: () => import("../views/vehicle/vehicleDetail.vue"),
      },
      //停车场列表
      {
        path: "/parkingLot/list",
        name: "parkingLotList",
        component: () =>
          import("../views/parkingLot/parkingLotList/parkingLotList.vue"),
      },
      //停车场详情
      {
        path: "/parkingLot/list/:id",
        name: "parkingLotDetail",
        component: () =>
          import("../views/parkingLot/parkingLotList/parkingLotDetail.vue"),
      },
      //联合停车场
      {
        path: "/parkingGroup/list",
        name: "parkingGroup",
        component: () =>
          import("../views/parkingLot/parkingGroup/parkingGroup.vue"),
      },
      //联合停车场详情
      {
        path: "/parkingGroup/list/:id",
        name: "parkingGroupDetail",
        component: () =>
          import("../views/parkingLot/parkingGroup/parkingGroupDetail.vue"),
      },
      //停车场商户管理详情
      {
        path: "/parkingLot/merchant/:id",
        name: "merchantDetail",
        component: () =>
          import(
            "../views/parkingLot/parkingLotList/tabs/merchant/merchantDetail.vue"
          ),
      },
      //联合停车场商户管理详情
      {
        path: "/parkingGroup/merchant/:id",
        name: "parkingGroup-merchantDetail",
        component: () =>
          import(
            "../views/parkingLot/parkingGroup/tabs/merchant/merchantDetail.vue"
          ),
      },
      //商圈列表
      {
        path: "/businessCircle/list",
        name: "businessCircleList",
        component: () =>
          import("../views/parkingLot/businessCircle/businessCircleList.vue"),
      },
      //普通用户列表
      {
        path: "/averageUser/list",
        name: "averageUserList",
        component: () =>
          import("../views/user/averageUser/averageUserList.vue"),
      },
      //普通用户详情
      {
        path: "/averageUser/list/:id",
        name: "averageUserDetail",
        component: () =>
          import("../views/user/averageUser/averageUserDetail.vue"),
      },
      //系统用户列表
      {
        path: "/systemUser/list",
        name: "systemUserList",
        component: () => import("../views/user/systemUser/systemUserList.vue"),
      },
      //用户反馈列表
      {
        path: "/advice/list",
        name: "adviceList",
        component: () => import("../views/user/advice/adviceList.vue"),
      },
      //用户反馈详情
      {
        path: "/advice/list/:info",
        name: "adviceDetail",
        component: () => import("../views/user/advice/adviceDetail.vue"),
      },
      //停车订单
      {
        path: "/stopOrder/list",
        name: "stopOrderList",
        component: () => import("../views/finance/stopOrder/stopOrderList.vue"),
      },
      //商户订单
      {
        path: "/merchantOrder/list",
        name: "merchantOrderList",
        component: () =>
          import("../views/finance/merchantOrder/merchantOrder.vue"),
      },
      //退款管理
      {
        path: "/refund/list",
        name: "refundList",
        component: () => import("../views/finance/refund/refund.vue"),
      },
      //钱包订单
      {
        path: "/walletOrder/list",
        name: "walletOrderList",
        component: () => import("../views/finance/walletOrder/walletOrder.vue"),
      },
      //发票管理
      {
        path: "/invoice/list",
        name: "invoiceList",
        component: () => import("../views/finance/invoice/invoiceList.vue"),
      },
      //发票抬头
      {
        path: "/invoiceAuth/list",
        name: "invoiceAuth",
        component: () => import("../views/finance/invoiceAuth/invoiceAuth.vue"),
      },
      //服务器列表
      {
        path: "/system/server/serverList",
        name: "serverList",
        component: () => import("../views/system/server/serverList.vue"),
      },
      //权限管理列表
      {
        path: "/system/authority/authorityList",
        name: "authorityList",
        component: () => import("../views/system/authority/authorityList.vue"),
      },
      //设备主动注册
      {
        path: "/device/register/list",
        name: "deviceRegister",
        component: () =>
          import("../views/system/deviceRegister/deviceRegister.vue"),
      },
      //注册IP列表
      {
        path: "/device/remoteIp/list",
        name: "deviceRemoteIp",
        component: () => import("../views/system/remoteIp/remoteIp.vue"),
      },
      //默认广告
      {
        path: "/advertise/default",
        name: "advertise-default",
        component: () => import("../views/advertise/default/default.vue"),
      },
      //停车场广告
      {
        path: "/advertise/parkingLot",
        name: "advertise-parkingLot",
        component: () => import("../views/advertise/parkingLot/parkingLot.vue"),
      },
      //停车场广告详情
      {
        path: "/advertise/parkingLot/:parkingLotId",
        name: "advertise-parkingLot-detail",
        component: () =>
          import("../views/advertise/parkingLot/parkingLotDetail.vue"),
      },
      //定点投放广告
      {
        path: "/advertise/fixed",
        name: "advertise-fixed",
        component: () => import("../views/advertise/fixed/fixed.vue"),
      },
      //定点投放广告详情
      {
        path: "/advertise/fixed/:areaId",
        name: "advertise-fixed-detail",
        component: () => import("../views/advertise/fixed/fixedDetail.vue"),
      },
      //本地特色推送
      {
        path: "/advertise/featuredPush",
        name: "advertise-featuredPush",
        component: () =>
          import("../views/advertise/featuredPush/featuredPush.vue"),
      },
      //图文视频推广
      {
        path: "/advertise/promotion",
        name: "advertise-promotion",
        component: () => import("../views/advertise/promotion/promotion.vue"),
      },
      //抓取记录
      {
        path: "/logs/capture",
        name: "captureLog",
        component: () => import("../views/logs/capture/capture.vue"),
      },
      // 道闸控制记录
      {
        path: "/logs/strobeControl",
        name: "strobeControlLog",
        component: () =>
          import("../views/logs/strobeControl/strobeControl.vue"),
      },
      // 套餐变动记录
      {
        path: "/logs/leaseChange",
        name: "leaseChange",
        component: () => import("../views/logs/leaseChange/leaseChange.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由守卫--路由跳转之后触发;
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0); //跳转之后回到顶部
});
// 路由守卫--路由跳转之前触发;
router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem("token")) {
    let controlAuthority = JSON.parse(
      sessionStorage.getItem("controlAuthority")
    );
    if (controlAuthority) {
      let pathNameList = controlAuthority.pathNameList;
      pathNameList.push("login");
      if (pathNameList.includes(to.name)) {
        next();
      } else {
        if (to.name !== "404") {
          next("/404");
        } else {
          next();
        }
      }
    } else {
      if (to.name !== "login") {
        sessionStorage.clear();
        next("/login");
      } else {
        next();
      }
    }
  } else {
    if (to.name !== "login") {
      sessionStorage.clear();
      next("/login");
    } else {
      next();
    }
  }
});
export default router;

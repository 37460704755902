import Vue from "vue";
import Vuex from "vuex";
import { Axios as http } from "@/utils/fetch";
import bigScreen from "@/store/modules/bigScreen";
import GangTing from "@/store/modules/GangTing";
import select from "@/store/modules/select";
import socket from "@/store/modules/socket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    //控制权限
    controlAuthority: null,
    // 内网环境
    isLocal: Boolean(localStorage.getItem("isLocal")),
  },
  mutations: {
    SET_USER(state, val) {
      state.user = val;
    },
    SET_TOKEN(state, val) {
      state.token = val;
    },
    RESET_USER(state) {
      state.user = null;
      state.token = null;
      sessionStorage.clear();
    },
    SET_CONTROLAUTHORITY(state, val) {
      state.controlAuthority = val;
    },
    SET_ISLOCAL(state, val) {
      state.isLocal = val;

      if (val) {
        localStorage.setItem("isLocal", true);
      } else {
        localStorage.removeItem("isLocal");
      }
    },
  },
  actions: {
    async refreshToken({ commit, dispatch }) {
      let res = await http.post("/token/refresh");
      if (res.code === 0) {
        sessionStorage.setItem("token", res.data.token);
        commit("SET_TOKEN", res.data.token);
        commit("SET_USER", {
          userName: res.data.username,
          userId: res.data.userId,
          authorities: res.data.authorities,
        });
        //隔一小时50分刷新token 防止过期
        setTimeout(() => {
          dispatch("refreshToken");
        }, 1000 * 60 * 110);
      } else {
        commit("RESET_USER");
      }
    },
  },
  modules: { bigScreen, GangTing, select, socket },
});

import coordtransform from "coordtransform"

export const wgs84tobd09 = function (longitude, latitude) {
    const data = coordtransform.wgs84togcj02(longitude, latitude)
    return coordtransform.gcj02tobd09(data[0], data[1])
}

export const exportExcel = function (response) {
    let blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    let a = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    a.href = href;
    a.download = response.headers["content-disposition"] &&
        decodeURI(
            response.headers["content-disposition"].split("filename=")[1]
        ) || ""; //下载后文件名
    document.body.appendChild(a);
    a.click(); //点击下载
    document.body.removeChild(a); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
}

export const formatRatio = function (num) {
    if (!num) return 0
    let val = (num * 100).toFixed(2)
    return Number(val.includes(".00") ? val.slice(0, -3) : val)
}

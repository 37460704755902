export default {
    namespaced: true,
    state:{
        parkingLotList:[],
    },
    mutations:{
        SET_PARKINGLOTLIST(state,list){
            state.parkingLotList = list
        }
    }
}

export default {
  //主体类型
  select_subjectType: [
    {
      value: "商业综合体",
      label: "商业综合体",
    },
    {
      value: "写字楼",
      label: "写字楼",
    },
    {
      value: "小区",
      label: "小区",
    },
    {
      value: "酒店",
      label: "酒店",
    },
    {
      value: "景区",
      label: "景区",
    },
  ],
  //车辆大小
  select_vehicleSize: [
    {
      value: "小型车",
      label: "小型车",
    },
    {
      value: "大型车",
      label: "大型车",
    },
  ],
  //租赁类型
  select_leaseType: [
    {
      value: "按日",
      label: "按日",
    },
    {
      value: "按月",
      label: "按月",
    },
    {
      value: "按年",
      label: "按年",
    },
    {
      value: "永久",
      label: "永久",
    },
    {
      value: "业主",
      label: "业主",
    },
  ],
  //优惠券类型
  select_couponType: [
    {
      value: "按时间",
      label: "按时间",
    },
    {
      value: "按金额",
      label: "按金额",
    },
    {
      value: "单次免费",
      label: "单次免费",
    },
  ],
  //时长类型
  select_durationType: [
    {
      value: "按日",
      label: "按日",
    },
    {
      value: "按月",
      label: "按月",
    },
    {
      value: "按年",
      label: "按年",
    },
  ],
  //套餐类型
  select_packageType: [
    {
      value: 1,
      label: "租赁",
    },
    {
      value: 2,
      label: "业主",
    },
    {
      value: 3,
      label: "产权",
    },
    {
      value: 4,
      label: "人防",
    },
  ],
  //停车状态
  select_parkingStatus: [
    // {
    //   label: "预入场",
    //   value: 0,
    // },
    {
      label: "确认入场",
      value: 1,
    },
    {
      label: "预出场",
      value: 2,
    },
    {
      label: "确认出场",
      value: 3,
    },
    {
      label: "异常出场",
      value: 4,
    },
  ],
  //离场类型
  select_departureType: [
    {
      label: "临时车",
      value: 0,
    },
    {
      label: "业主车",
      value: 1,
    },
    {
      label: "租赁车(含产权、人防)",
      value: 2,
    },
    {
      label: "异常车(无入场纪录)",
      value: 3,
    },
    {
      label: "手动强制出场",
      value: 4,
    },
    {
      label: "系统强制出场",
      value: 5,
    },
    {
      label: "遥控器开闸出场",
      value: 6,
    },
  ],
  //是否
  select_whether: [
    {
      value: "是",
      label: "是",
    },
    {
      value: "否",
      label: "否",
    },
  ],
  //开票税点
  select_taxRate: [
    {
      value: 0,
      label: 0,
    },
    {
      value: 0.01,
      label: 0.01,
    },
    {
      value: 0.015,
      label: 0.015,
    },
    {
      value: 0.03,
      label: 0.03,
    },
    {
      value: 0.04,
      label: 0.04,
    },
    {
      value: 0.05,
      label: 0.05,
    },
    {
      value: 0.06,
      label: 0.06,
    },
    {
      value: 0.09,
      label: 0.09,
    },
    {
      value: 0.1,
      label: 0.1,
    },
    {
      value: 0.11,
      label: 0.11,
    },
    {
      value: 0.13,
      label: 0.13,
    },
    {
      value: 0.16,
      label: 0.16,
    },
    {
      value: 0.17,
      label: 0.17,
    },
  ],
  //订单状态
  select_orderStatus: [
    {
      value: "订单生成",
      label: "订单生成",
    },
    {
      value: "待支付",
      label: "待支付",
    },
    {
      value: "支付成功",
      label: "支付成功",
    },
    {
      value: "支付失败",
      label: "支付失败",
    },
    {
      value: "交易关闭",
      label: "交易关闭",
    },
    {
      value: "支付取消",
      label: "支付取消",
    },
    {
      value: "已退款",
      label: "已退款",
    },
  ],
  //支付类型
  select_platformType: [
    {
      value: "微信公众号",
      label: "微信公众号",
    },
    {
      value: "微信小程序",
      label: "微信小程序",
    },
    {
      value: "支付宝小程序",
      label: "支付宝小程序",
    },
    {
      value: "App",
      label: "App",
    },
    {
      value: "甬城泊车",
      label: "甬城泊车",
    },
    {
      value: "现金支付",
      label: "现金支付",
    },
    {
      value: "第三方平台",
      label: "第三方平台",
    },
  ],
  //放行类型
  select_releaseType: [
    {
      value: "异常放行",
      label: "异常放行",
    },
    {
      value: "收费放行",
      label: "收费放行",
    },
  ],
  //广告位置
  select_adPosition: [
    {
      value: "首页活动",
      label: "首页活动",
    },
    {
      value: "支付广告",
      label: "支付广告",
    },
  ],
  //广告状态
  select_adStatus: [
    {
      value: "审核中",
      label: "审核中",
    },
    {
      value: "审核未通过",
      label: "审核未通过",
    },
    {
      value: "审核通过",
      label: "审核通过",
    },
  ],
  //广告发布状态
  select_publishStatus: [
    {
      value: "已下架",
      label: "已下架",
    },
    {
      value: "已发布",
      label: "已发布",
    },
  ],
  //广告类型
  select_advtisementType: [
    {
      value: "平台",
      label: "平台",
    },
    {
      value: "停车场",
      label: "停车场",
    },
    {
      value: "区域",
      label: "区域",
    },
  ],
  select_ZheJiang_city: [
    {
      value: "杭州市",
      label: "杭州市",
    },
    {
      value: "宁波市",
      label: "宁波市",
    },
    {
      value: "温州市",
      label: "温州市",
    },
    {
      value: "嘉兴市",
      label: "嘉兴市",
    },
    {
      value: "湖州市",
      label: "湖州市",
    },
    {
      value: "绍兴市",
      label: "绍兴市",
    },
    {
      value: "金华市",
      label: "金华市",
    },
    {
      value: "衢州市",
      label: "衢州市",
    },
    {
      value: "舟山市",
      label: "舟山市",
    },
    {
      value: "台州市",
      label: "台州市",
    },
    {
      value: "丽水市",
      label: "丽水市",
    },
  ],
  //抬杆类型
  select_liftType: [
    {
      value: "系统",
      label: "系统",
    },
    {
      value: "遥控",
      label: "遥控",
    },
    {
      value: "人为",
      label: "人为",
    },
  ],
  //开票状态
  select_invoiceStatus: [
    {
      value: "开票完成",
      label: "开票完成",
    },
    {
      value: "开票失败",
      label: "开票失败",
    },
    {
      value: "发票审核中",
      label: "发票审核中",
    },
    {
      value: "冲红审核中",
      label: "冲红审核中",
    },
  ],
  //开闸异常类型
  select_releaseReason: [
    {
      value: "军、警等公务车辆",
      label: "军、警等公务车辆",
    },
    {
      value: "包月车",
      label: "包月车",
    },
    {
      value: "记录异常，无入场匹配信息",
      label: "记录异常，无入场匹配信息",
    },
    {
      value: "缴费后未抬杆",
      label: "缴费后未抬杆",
    },
    {
      value: "因场地或其他故障原因导致交通堵塞",
      label: "因场地或其他故障原因导致交通堵塞",
    },
    {
      value: "其他原因",
      label: "其他原因",
    },
  ],
  //开闸放行类型
  select_releaseType: [
    {
      value: "异常放行",
      label: "异常放行",
    },
    {
      value: "收费放行",
      label: "收费放行",
    },
  ],
  //订单类型
  select_recordType: [
    {
      value: "用户充值",
      label: "用户充值",
    },
    {
      value: "优惠券扣款",
      label: "优惠券扣款",
    },
  ],
};

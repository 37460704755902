import Vue from "vue";
import "aliyun-webrtc-sdk";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

//引入element-ui组件库
import ElementUI from "element-ui";
import "@/assets/css/element-variables.scss";
Vue.use(ElementUI);
Vue.prototype.$ELEMENT = { size: "small" };

// 配置 m3u8 直播的播放器
import "video.js/dist/video-js.css";
import hls from "videojs-contrib-hls";
Vue.use(hls);

// axios配置
import { Axios } from "@/utils/fetch";
Vue.prototype.$http = Axios;

//引入moment时间库
import moment from "moment";
Vue.prototype.$moment = moment;

//全局混入
import publicData from "@/utils/publicData";
import selectData from "@/utils/selectData";
import typeFormat from "@/utils/typeFormat";
Vue.mixin({
  data() {
    return {
      ...publicData,
      ...selectData,
    };
  },
  computed: {
    buttonList() {
      return store.state.controlAuthority.buttonList;
    },
  },
  methods: {
    ...typeFormat,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
